import React from 'react';

function ISO27001() {
    return (
        <section id="iso27001">
            <img src="cybersecurity_iso27001.png" alt="ISO 27001 Compliance" />
            <div className="section-content">
                <h2>ISO 27001 – Enterprise Security Governance & Risk Management</h2>
                <p>Cyber resilience is no longer optional—it’s a business imperative. Organizations operating in regulated industries must demonstrate robust security governance to safeguard sensitive information and mitigate financial and reputational risks.</p>
                <ul>
                    <li><strong>End-to-End ISO 27001 Certification Support</strong> – From gap analysis to full compliance, we ensure your ISMS is audit-ready.</li>
                    <li><strong>Risk-Based Security Frameworks</strong> – Aligning security with business objectives, ensuring confidentiality, integrity, and availability of critical assets.</li>
                    <li><strong>Continuous Compliance & Audit Readiness</strong> – Security is not a one-time exercise. We help organizations stay compliant and secure in an evolving threat landscape.</li>
                </ul>
            </div>
        </section>
    );
}

export default ISO27001;
