import React from 'react';

function Contact() {
    return (
        <section id="contact">
            <img src="cybersecurity_contact.png" alt="Cybersecurity Contact" />
            <div className="section-content">
                <h2>Contact</h2>
                <p>Email: <a href="mailto:john@oxcomm.co.nz">john@oxcomm.co.nz</a></p>
            </div>
        </section>
    );
}

export default Contact;
