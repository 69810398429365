import React from 'react';

function Certifications() {
    return (
        <section id="certifications">
            <img src="cybersecurity_certifications.png" alt="Cybersecurity Certifications" />
            <div className="section-content">
                <h2>Certifications</h2>
                <p>Expertise matters in cybersecurity, and our team holds industry-leading certifications that demonstrate deep knowledge across governance, risk, compliance, and technical security domains. From cloud security and AI risk management to ethical hacking and IT governance, our qualifications ensure a strategic, well-rounded approach to protecting your business. With globally recognized credentials such as CISSP, CISM, CISA, and AWS AI Foundation, we bring the technical proficiency and risk management expertise needed to navigate today’s evolving threat landscape.</p>
                <ul>
                    <li>CISSP - Certified Information Systems Security Professional</li>
                    <li>CISM - Certified Information Security Manager</li>
                    <li>CISA - Certified Information Security Auditor</li>
                    <li>AWS - AI Foundation Practitioner</li>
                </ul>
            </div>
        </section>
    );
}

export default Certifications;
