import React from 'react';

function Services() {
    return (
        <section id="services">
            <img src="cybersecurity_risk.png" alt="Cybersecurity Risk Assessment" />
            <div className="section-content">
                <h2>Services</h2>
                <p>Effective cybersecurity is more than just technology — it requires a strategic approach to governance, risk, and compliance. Organizations must align security frameworks with business objectives, manage evolving threats, and ensure resilience in an increasingly complex digital environment. Our services are designed to help businesses navigate security challenges with confidence, from governance and compliance to risk management, cloud security, and incident response. Whether strengthening regulatory compliance, assessing cyber risk, or securing AI-driven technologies, we provide the expertise to protect your business and enhance resilience.</p>
                <ul>
                    <li>Cybersecurity Governance & Compliance (ISO 27001, PCI-DSS, NZISM)</li>
                    <li>Security Risk Assessment & Management</li>
                    <li>Cloud Security & AI Security Consulting</li>
                    <li>Incident Response & Business Continuity</li>
                </ul>
            </div>
        </section>
    );
}

export default Services;
