import React from 'react';

function Nav() {
    return (
        <nav>
            <ul>
                <li><a href="#about">About</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#certifications">Certifications</a></li>
                <li><a href="#nzism">NZISM</a></li>
                <li><a href="#iso27001">ISO 27001</a></li>
                <li><a href="#pcidss">PCI DSS</a></li>
                <li><a href="#vulnerabilities">Vulnerabilities</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
        </nav>
    );
}

export default Nav;
