import React from 'react';
import './App.css'; // Import your CSS file
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
import Certifications from './components/Certifications';
import NZISM from './components/NZISM';
import ISO27001 from './components/ISO27001';
import PCIDSS from './components/PCIDSS';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
    return (
        <div>
            <Header />
            <Nav />
            <main>
                <About />
                <Services />
                <Certifications />
                <NZISM />
                <ISO27001 />
                <PCIDSS />
                <Contact />
            </main>
            <Footer />
        </div>
    );
}

export default App;
