import React from 'react';

function About() {
    return (
        <section id="about">
            <img src="server_room.png" alt="Cybersecurity Defense" />
            <div className="section-content">
                <h2>In Today’s Fast-Evolving Cyber Landscape…</h2>
                <p>Cyber threats are more frequent and sophisticated than ever, exposing organizations to financial, operational, and reputational risk.
                    As security breaches escalate worldwide, businesses must navigate an increasingly complex regulatory landscape, safeguard critical data, and build resilience against evolving attack vectors.
                    <br /><br />Governance, Risk, and Compliance (GRC) is at the core of effective cybersecurity—aligning strategy, controls, and compliance to mitigate risk and ensure operational integrity.
                    Our service empowers organizations to proactively manage cyber risk, strengthen security postures, and achieve regulatory confidence in an unpredictable digital world.</p>
            </div>
        </section>
    );
}

export default About;
