import React from 'react';

function Header() {
    return (
        <header>
            <img src="oxcomm_logo.png" alt="OxComm Cybersecurity Logo" />
            <p className="tagline">Defending Your Digital Future</p>
        </header>
    );
}

export default Header;
