import React from 'react';

function PCIDSS() {
    return (
        <section id="pcidss">
            <img src="cybersecurity_pcidss.png" alt="PCI DSS Compliance" />
            <div className="section-content">
                <h2>PCI DSS – Securing Payment Systems & Financial Transactions</h2>
                <p>In today’s digital economy, payment fraud and financial cybercrime are escalating. PCI DSS (Payment Card Industry Data Security Standard) compliance is essential for businesses handling credit card transactions, ensuring customer trust and protecting financial ecosystems from data breaches.</p>
                <ul>
                    <li><strong>PCI DSS Gap Analysis & Risk Assessments</strong> – Identifying vulnerabilities in your payment environment.</li>
                    <li><strong>Security Controls Implementation</strong> – Encrypting data, securing cardholder information, and preventing unauthorized access.</li>
                    <li><strong>Audit & Certification Support</strong> – Ensuring seamless compliance with PCI DSS requirements.</li>
                </ul>
            </div>
        </section>
    );
}

export default PCIDSS;
