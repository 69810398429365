import React from 'react';

function Footer() {
    return (
        <footer>
            <p>© 2025 OxComm Cybersecurity</p>
        </footer>
    );
}

export default Footer;
