import React from 'react';

function NZISM() {
    return (
        <section id="nzism">
            <img src="cybersecurity_nzism.png" alt="NZISM Compliance" />
            <div className="section-content">
                <h2>NZISM – Securing Government & Public Sector Infrastructure</h2>
                <p>With government agencies increasingly targeted by state-sponsored cyber threats and data breaches, compliance with NZISM (New Zealand Information Security Manual) is paramount. At <strong>OxComm Cybersecurity</strong>, we bring deep expertise in NZISM accreditation, certification, and risk assessments, helping agencies strengthen their security posture while ensuring compliance with government standards.</p>
                <ul>
                    <li><strong>Certification & Accreditation</strong> for government solutions, ensuring full alignment with NZISM controls.</li>
                    <li><strong>Risk & Compliance Assessments</strong> to mitigate vulnerabilities before they become threats.</li>
                    <li><strong>Security Architecture Reviews</strong> to embed security from the ground up.</li>
                </ul>
            </div>
        </section>
    );
}

export default NZISM;
